// userStore.js
import { defineStore } from 'pinia';
import { ref } from 'vue';
import router from '@/router'; // Import the router instance

// export const API_BASE_URL = 'http://localhost:8000/api';
// export const API_BASE_URL = 'https://193.93.251.154:443';
export const API_BASE_URL = 'https://effektivflyt.no/api';
// export const API_BASE_URL = 'http://193.93.251.154:8010'
export const ACCESS_TOKEN_KEY = 'accessToken';
export const NAME = 'name';

export const useUserStore = defineStore('userStore', {
    state: () => ({
        name: ref(''),
        username: ref(''),
        password: ref(''),
        accessToken: null,
        loading: false,
        errorMessages: '',
        succesMessages: ''
    }),
    actions: {
        async initialize() {
            const storedToken = localStorage.getItem(ACCESS_TOKEN_KEY);
            if (storedToken) {
                this.accessToken = storedToken;
            }
        },
        async login() {
            this.loading = true;
            const formData = new FormData();
            formData.append('username', this.username);
            formData.append('password', this.password);
            try {
                const response = await fetch(`${API_BASE_URL}/users/token`, {
                    method: 'POST',
                    body: formData
                });

                if (!response.ok) {
                    const errorData = await response.json();
                    console.error('Error response data:', errorData);
                    const errorMessage = errorData.detail || 'Failed to log in';
                    throw new Error(errorMessage);
                }

                const data = await response.json();
                this.accessToken = data.access_token;
                this.name = data.name;
                
                // Store access token in localStorage
                localStorage.setItem(ACCESS_TOKEN_KEY, this.accessToken);
                localStorage.setItem(NAME, this.name)

                // Redirect to home page after successful login
                router.push('/');
            } catch (error) {
                console.error('Error logging in:', error);
                throw error;
            } finally {
                this.loading = false;
            }
        },
        async logout() {
            // Clear access token from store
            this.accessToken = null;
            // Clear access token from localStorage
            localStorage.removeItem(ACCESS_TOKEN_KEY);
            localStorage.removeItem(NAME);
            // Redirect to login page
            router.push('/login');
        },
        async addUser(newUser) {
          try {
            const response = await fetch(`${API_BASE_URL}/users/add-new-user`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(newUser)
            });
    
            if (!response.ok) {
              console.log("YYYY")
              const errorData = await response.json();
              const errorMessage = errorData.detail || 'User with this email already exists';
              this.errorMessages = errorMessage;
              console.log(errorData)
            } else {
              const responseData = await response.json();
              console.log("YES")
              const successMessage = 'New user created';
              this.succesMessages = successMessage;
            }
          } catch (error) {
            this.setError(error.message);
          }
        },
        setError(message) {
          this.errorMessage = message;
        },
        setSuccess(message) {
          this.successMessage = message;
        }
      }
      
    
});
